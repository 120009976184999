import { Box, CircularProgress, Typography } from "@mui/material";
import { getNoTicketsText } from "src/core/helpers/getNoTicketsText";
import { ChevronDownIcon } from "src/components/icons/ChevronDown";
import { IPrioritySlot, ISlot, ISlotData, SlotKey } from "core/types/bundles";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectedBundle from "src/components/molecules/BundleComponent/components/SelectedBundle";
import {
  Filters,
  HeaderItem,
  HeaderItemText,
  HeaderWrapper,
  Image,
  LoadingWrapper,
  NoTicketsText,
  NoTicketsWrapper,
  TicketHeader,
  TicketWrapper,
  Title,
  Wrapper,
} from "src/components/molecules/BundleComponent/components/styledComponents";
import TableTicket from "src/components/molecules/BundleComponent/components/TableTicket";
import { TableTicketMobile } from "src/components/molecules/BundleComponent/components/TableTicketMobile";
//TODO: refactor -> divide to bigger components
import PreferButtons from "src/components/molecules/PreferButtons";
import { Loc } from "src/core/types/loc";
import { TicketFilterPayload } from "src/core/types/reduxTypes";
import { filterTimeByHalfHour } from "src/core/utils/filterSlotsByHalfHour";
import { filterTimeSlots } from "src/core/utils/filterTimeSlots";
import { guidedHeader, unGuidedHeader } from "src/core/utils/ticketsHeader";

const fadeInVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};

const BundleComponent = ({
  bundle,
  loc,
  tour,
  isSelected,
  selectSlot,
  selectedSlot,
  deleteSlot,
  is_alone,
  is_first,
  isAvailable,
  parentSlot,
}: {
  bundle: ISlotData | undefined;
  loc: Loc;
  tour: SlotKey;
  isSelected: boolean;
  selectSlot: (slot: ISlot, tour: SlotKey) => void;
  selectedSlot: IPrioritySlot;
  deleteSlot: (tour: SlotKey) => void;
  is_alone: boolean;
  is_first: boolean;
  isAvailable: boolean;
  parentSlot: IPrioritySlot | undefined;
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const { t } = useTranslation();
  const headerArray = bundle?.can_have_exposition ? guidedHeader : unGuidedHeader;
  const [selectedFilter, setSelectedFilter] = useState<TicketFilterPayload>("everytime");

  const [isChevronVisible, setChevronVisible] = useState(true);
  const listRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (!listRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 5;
    setChevronVisible(!isAtBottom);
  };

  useEffect(() => {
    const scrollableContainer = listRef.current;
    if (scrollableContainer) {
      scrollableContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableContainer) {
        scrollableContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleScrollClick = () => {
    if (!listRef.current) return;
    listRef.current.scrollBy({
      top: 200,
      behavior: "smooth",
    });
  };

  const shouldShowWarning = (slot: IPrioritySlot) => {
    if (!slot || !parentSlot) return false;

    const [slotHours, slotMinutes] = slot.time.split(":").map(Number);
    const [parentHours, parentMinutes] = parentSlot.time.split(":").map(Number);

    const slotTotalMinutes = slotHours * 60 + slotMinutes;
    const parentTotalMinutes = parentHours * 60 + parentMinutes;

    const timeDifference = Math.abs(slotTotalMinutes - parentTotalMinutes);

    return timeDifference <= 30;
  };

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <AnimatePresence mode="wait">
      {isSelected ? (
        <motion.div
          key="selected"
          layout
          variants={fadeInVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ duration: 0.3 }}
          style={{ width: "100%" }}
        >
          <SelectedBundle
            slot={selectedSlot}
            tour={tour}
            is_alone={is_alone}
            is_first={is_first}
            bundle={bundle || undefined}
            deleteSlot={deleteSlot}
            loc={loc}
          />
        </motion.div>
      ) : (
        <motion.div
          key="unselected"
          layout
          variants={fadeInVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ duration: 0.3 }}
          style={{ width: "100%" }}
        >
          <Wrapper
            isEmpty={!bundle || bundle?.slots.length === 0}
            style={!isChevronVisible && isMobile ? { marginBottom: "40px" } : {}}
          >
            {!bundle ? (
              <LoadingWrapper>
                <CircularProgress color={"error"} />
              </LoadingWrapper>
            ) : (
              <Box>
                <HeaderWrapper>
                  <Image src={bundle.image_url} />
                  <Title>{bundle.title[loc] || bundle.title.cs || ""}</Title>
                </HeaderWrapper>
                {bundle.slots.length !== 0 && (
                  <Filters>
                    <PreferButtons
                      setSelectedOption={setSelectedFilter}
                      selectedOption={selectedFilter}
                    />
                  </Filters>
                )}
                {filterTimeSlots({ slots: bundle.slots, filter: selectedFilter }).length > 0 ? (
                  <>
                    {!isMobile && (
                      <TicketHeader>
                        {headerArray.map((element) => {
                          if (!bundle.can_have_exposition && element.id === "story_name") {
                            return null;
                          }
                          return (
                            <HeaderItem key={element.id}>
                              <HeaderItemText>{element.label}</HeaderItemText>
                            </HeaderItem>
                          );
                        })}
                      </TicketHeader>
                    )}

                    <TicketWrapper ref={listRef}>
                      {bundle &&
                        filterTimeByHalfHour({
                          timeString: parentSlot?.time,
                          slots: filterTimeSlots({ slots: bundle.slots, filter: selectedFilter }),
                        }).map((slot: IPrioritySlot, i: number) => {
                          return !isMobile ? (
                            <TableTicket
                              key={slot.id}
                              slot={slot}
                              bundle={bundle}
                              tour={tour}
                              loc={loc}
                              warning={!!parentSlot && i === 0 && shouldShowWarning(slot)}
                              selectSlot={selectSlot}
                              isAllowed={isAvailable}
                            />
                          ) : (
                            <TableTicketMobile
                              key={slot.id}
                              slot={slot}
                              bundle={bundle}
                              tour={tour}
                              loc={loc}
                              warning={!!parentSlot && i === 0 && shouldShowWarning(slot)}
                              selectSlot={selectSlot}
                              isAllowed={isAvailable}
                            />
                          );
                        })}
                    </TicketWrapper>
                  </>
                ) : (
                  bundle.slots.length === 0 && (
                    <NoTicketsWrapper>
                      <NoTicketsText>
                        <Typography
                          variant="body2"
                          dangerouslySetInnerHTML={{
                            __html: t(getNoTicketsText({ tourName: tour })),
                          }}
                        />
                      </NoTicketsText>
                    </NoTicketsWrapper>
                  )
                )}
                {bundle.slots.length > 3 && isChevronVisible && (
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "none",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      height: "20px",
                      my: "10px",
                      "@media (max-width: 768px)": {
                        display: "flex",
                      },
                    }}
                    onClick={handleScrollClick}
                  >
                    <ChevronDownIcon />
                  </Box>
                )}
              </Box>
            )}
          </Wrapper>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BundleComponent;
