import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { SchoolContentText } from "src/components/atoms/SchoolContentText";
import { ContentPicture } from "src/components/molecules/ContentPicture";
import mainPhoto from "src/assets/img/global/side_page_cover_photo.jpg";
import phoneIcon from "../../../assets/phone-call.svg";
import { Loc } from "src/core/types/loc";

export const PageSchool = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const topRef = useRef<HTMLDivElement>(null);
  const mainRef = useRef<HTMLDivElement>(null);
  const numberRef = useRef<HTMLDivElement | null>(null);
  const phoneButtonCLick = (): void => {
    window.scrollTo({
      top: mainRef.current?.offsetTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (topRef && topRef.current) {
      window.scrollTo({
        top: topRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <Box ref={topRef}>
      <ContentPicture
        title={"visit_with_school"}
        buttonText={
          loc === "de" ? (isMobile ? "call_invitation_mob" : "call_invitation") : "call_invitation"
        }
        subtitle={"jacobs_church_in_brno"}
        mainPhoto={mainPhoto}
        click={phoneButtonCLick}
        buttonIcon={phoneIcon}
      />
      <SchoolContentText ref={mainRef} />
    </Box>
  );
};
