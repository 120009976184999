import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import SuccessIcon from "src/components/icons/SuccessIcon";
import BadgesComponent from "src/components/molecules/BadgesComponent";

import mainPhoto from "src/assets/img/global/side_page_cover_photo.jpg";
import styles from "./styles.module.css";

interface PageSuccessfulResultDesktopProps {
  price?: number;
}

const PageSuccessfulResultDesktop: React.FC<PageSuccessfulResultDesktopProps> = ({ price }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (price !== 0) {
      window.gtag("event", "purchase", {
        event_category: "Purchase",
        event_label: "Purchased tickets",
        value: price,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <img className={styles.image} src={mainPhoto} alt="" />
      <div className={styles.wrapper}>
        <div className={styles.header} dangerouslySetInnerHTML={{ __html: t("payment_success") }} />
        <div className={styles.middle_icon}>
          <SuccessIcon />
        </div>
        <div
          className={styles.sub_descirption}
          dangerouslySetInnerHTML={{ __html: t("next_info_desktop") }}
        />
        <div className={styles.dotted_line} />
        <div className={styles.description}>{t("download_application")}</div>
        <div className={styles.sub_descirption}>
          {t("have_tickets")} <br />
          {t("mobile_and_app")}
        </div>
        <BadgesComponent />
        <div className={styles.dotted_line} />
        <div className={styles.button_home} onClick={() => navigate("/")}>
          {t("home")}
        </div>
      </div>
    </>
  );
};

export default PageSuccessfulResultDesktop;
