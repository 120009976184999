import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import VisitConditions from "src/components/atoms/VisitConditions";
import { Logo } from "src/components/atoms/Logo";
import colorPayment from "src/assets/payment_color.svg";
import { useTranslation } from "react-i18next";
import {
  CheckBoxWrapper,
  Content,
  InfoContent,
  InfoSubContent,
  LogoWrapper,
  PayButton,
  PaymentContent,
  Wrapper,
} from "src/components/molecules/ReservationComponent/styledComponents";
import { useSelector } from "react-redux";
import { RootState } from "src/core/redux/store";
import { Loc } from "src/core/types/loc";
import { ITour, SlotKey } from "core/types/bundles";
import TourComponent from "src/components/molecules/ReservationComponent/components/TourComponent";
import PersonComponent from "src/components/molecules/ReservationComponent/components/PersonComponent";
import TicketsReceipt from "src/components/atoms/TicketsReceipt";
import { CheckOutStep, Discount, IReservation, Tab, TourList } from "src/core/types";
import { StateValue } from "src/core/types/action";
import { usePostReservation } from "src/core/queries/usePostReservation";
import { CheckboxComponent } from "src/components/atoms/Checkbox";
import { PaymentButton } from "src/components";
import { setStepToPen } from "src/core/utils";
import PaymentDiscountComponent from "src/components/molecules/PaymentDiscountComponent";

import styles from "./styles.module.css";

interface ReservationProps {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  step: CheckOutStep;
  panels: CheckOutStep[];
  tabs: Tab[];
}

const ReservationComponent = ({ updateCheckOutState, step, panels, tabs }: ReservationProps) => {
  const loc: Loc = (sessionStorage.getItem("loc") as Loc) || "cs";
  const { t } = useTranslation();
  const selectedBundle = useSelector((state: RootState) => state.bundle);
  const selectedSlots = useSelector((state: RootState) => state.timeSlot);
  const selectedTours = useSelector((state: RootState) => state.selectedTours);
  const reservationID = useSelector((state: RootState) => state.reservationID?.reservationID);
  const reservationData = useSelector((state: RootState) => state.reservationData);
  const [currentDiscount, setCurrentDiscount] = useState<string>("");
  const [discountInfo, setDiscountInfo] = useState<Discount>({} as Discount);

  const [consentState, setConsentState] = useState<{
    [key in "consentOneState" | "consentTwoState" | "consentThreeState"]: boolean;
  }>({
    consentOneState: false,
    consentTwoState: false,
    consentThreeState: false,
  });

  const onConsentChange = (
    consentKey: "consentOneState" | "consentTwoState" | "consentThreeState",
    value: boolean,
  ) => {
    setConsentState((prev) => ({ ...prev, [consentKey]: value }));
  };

  const getTourName = (tour: SlotKey): string => {
    if (selectedTours) {
      return selectedTours[tour].title[loc];
    }
    return "";
  };

  const goToCurrentStep = (stepKey: number) => {
    const { newTabs, newPanels } = setStepToPen({
      tabs: tabs,
      panels: panels,
      currentKey: stepKey,
    });
    updateCheckOutState(newTabs, newPanels);
  };

  //queries
  const { mutate, isLoading } = usePostReservation();

  const handleSubmit = () => {
    if (isLoading) return;
    if (
      reservationID &&
      reservationData &&
      selectedBundle &&
      reservationData.userData &&
      consentState.consentOneState &&
      consentState.consentTwoState
    ) {
      const submitData: IReservation = {
        reservation: reservationID ?? "",
        bundle: selectedBundle.name,
        name: reservationData.userData.name,
        email: reservationData.userData.email,
        discount: currentDiscount && currentDiscount,
        phone_number: Number(reservationData.userData.phone_number),
        send_promo: false,
        order_type: "single",
      };
      mutate(submitData);
    }
  };

  const getTourTickets = (tour: SlotKey): number => {
    if (
      reservationData &&
      reservationData.selectedTickets &&
      reservationData.selectedTickets[tour]
    ) {
      return reservationData.selectedTickets[tour].reduce((acc, curr) => {
        return acc + curr.amount;
      }, 0);
    }
    return 0;
  };

  const getTourPrice = (tour: SlotKey): number => {
    let total = 0;
    if (
      reservationData &&
      reservationData.tickets &&
      reservationData.selectedTickets &&
      reservationData.selectedTickets[tour]
    ) {
      reservationData.selectedTickets[tour].forEach((ticket: StateValue) => {
        reservationData.tickets?.forEach((ticketType: TourList) => {
          if (ticket.type === ticketType.id) {
            total += Number(ticketType.price) * Number(ticket.amount);
          }
        });
      });
    }
    return total;
  };

  return step.isEnabled ? (
    <>
      <Wrapper>
        <Stack
          justifyContent="start"
          alignItems="center"
          gap="10px"
          width="100%"
          marginBottom="40px"
          direction="row"
        >
          <img src={colorPayment} alt="Payment" />
          <Typography variant="body1" color="#062762" fontWeight="bold">
            {t("recap_and_payment")}
          </Typography>
        </Stack>
        <Content>
          <InfoContent>
            <InfoSubContent>
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
              {selectedBundle &&
                selectedBundle.tours.map((tour: ITour, i) => (
                  <TourComponent
                    key={i}
                    item={selectedBundle.items[i]}
                    loc={loc}
                    tourName={getTourName(tour.name)}
                    date={reservationData?.selectedDate || undefined}
                    slot={selectedSlots[tour.name]}
                    prevDateStep={() => goToCurrentStep(1)}
                    ticketAmount={getTourTickets(tour.name)}
                    prevTicketStep={() => goToCurrentStep(2)}
                  />
                ))}
            </InfoSubContent>
            {reservationData && reservationData.userData && (
              <InfoSubContent>
                <PersonComponent
                  person={reservationData.userData}
                  prevStep={() => goToCurrentStep(3)}
                />
              </InfoSubContent>
            )}
          </InfoContent>
          <PaymentContent>
            <Stack direction={{ xs: "column-reverse", sm: "column" }} width="100%" spacing={2}>
              <Stack direction="column" width="100%" spacing={2}>
                <PaymentDiscountComponent
                  discountInfo={discountInfo}
                  setCurrentDiscount={setCurrentDiscount}
                  setDiscountInfo={setDiscountInfo}
                  bundle={selectedBundle}
                  getTourPrice={getTourPrice}
                />
                <CheckBoxWrapper>
                  <CheckboxComponent
                    onConsentChange={onConsentChange}
                    consentState={consentState}
                  />
                </CheckBoxWrapper>
              </Stack>
              <TicketsReceipt
                bundle={selectedBundle}
                getTourPrice={getTourPrice}
                discount={discountInfo}
                isRecapPayment={true}
                getTourName={getTourName}
              />
            </Stack>
            <PaymentButton
              isLoading={isLoading}
              consentState={consentState}
              onSubmit={handleSubmit}
            />
          </PaymentContent>
        </Content>
      </Wrapper>
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        sx={{ ">div": { maxWidth: "1360px", width: "100%" } }}
      >
        <VisitConditions />
      </Box>
    </>
  ) : null;
};

export default ReservationComponent;
